﻿import React, {CSSProperties} from "react";
import {Color, Size} from "@vivli/shared/theme";
import {PlanningTableComponent} from "@vivli/shared/components";
import {useActiveUser} from "@vivli/core/infrastructure/context";
import {useOrganizations} from "@vivli/features/organizations/infrastructure/hook";
import {useVivliConfig} from "@vivli/core/infrastructure/hook";
import {IDropdownMenuItem} from "@vivli/shared/infrastructure/interface";
import {useDataRequestContext,} from "@vivli/features/data-requests/infrastructure/context";
import {DuaExecutionComponent} from "./dua-execution.component";


const mainContainerStyle: CSSProperties = {
    paddingLeft: Size.INSET_SIZE,
    paddingRight: Size.INSET_SIZE,
    paddingBottom: Size.INSET_SIZE,
    backgroundColor: Color.LIGHT_GRAY
}

const planningStyle: CSSProperties = {
    display: "flex",
    gap: "20px",
    width: "70%"
};


export const DuaComponent = ({isOrgAdmin}) => {
    const organizations = useOrganizations().organizations;
    const { isVivliAdmin } = useActiveUser();
    const vivliConfig = useVivliConfig();
    const {planningTableData, setPlanningTableData} = useDataRequestContext();


    // Should be cleaned up and re-structured in the story 10905
    const alphabeticalSort = (a, b) => {
        return a.title < b.title ? -1 : 1;
    };
    const organizationsOptions = organizations
        ?.map(
            (org) =>
                ({
                    title: org.name,
                    value: org.id,
                    code: org.code,
                } as IDropdownMenuItem)
        )
        .sort(alphabeticalSort);

    const templateOptions = vivliConfig?.duaTemplateItems
        ?.map(
            (item) =>
                ({
                    title: item.displayName,
                    value: item.code
                } as IDropdownMenuItem)
        ).sort(alphabeticalSort);

    const getDefaultTemplateCode = (orgId) => {
        const organization = organizations?.find((org) => org.id === orgId);
        return organization ? organization.defaultDuaTemplateCode : null;
    };

    const handleAddRow = () => {
        setPlanningTableData([...planningTableData, {
            contributorName: '',
            selectedTemplateCode: '',
            contributorTemplateNotes: ''
        }]);
    };

    const handleDeleteRow = (index) => {
        const newData = [...planningTableData];
        newData.splice(index, 1);
        setPlanningTableData(newData);
    };

    const handleUpdateRow = (index, field, value) => {
        const newData = [...planningTableData];
        newData[index][field] = value;
        setPlanningTableData(newData);
    };

    return (
        <div style={mainContainerStyle}>

            {organizations && <>

                {isVivliAdmin && <>

                    <h2>Planning</h2>
                    <div style={planningStyle}>
                        <PlanningTableComponent templateOptions={templateOptions}
                                                handleAddRow={handleAddRow}
                                                planningTableData={planningTableData}
                                                handleDeleteRow={handleDeleteRow}
                                                handleUpdateRow={handleUpdateRow}
                                                getDefaultTemplateCode={getDefaultTemplateCode}
                                                organizationsOptions={organizationsOptions}
                        />
                    </div>

                </>}


                <h2>Execution</h2>
                <DuaExecutionComponent isOrgAdmin={isOrgAdmin}/>
            </>}
        </div>
    )
}









